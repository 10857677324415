<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-yellow-800 text-white' : 'text-yellow-100 hover:bg-yellow-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-yellow-300" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-gray-900 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component -->
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div class="mt-5 flex-1 flex flex-col">
            <nav class="flex-1 px-2 space-y-1">
              <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset md:hidden" :class="`focus:${ringbrand500} `" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">

          <div class="ml-4 flex items-center md:ml-6">
             <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

            <!-- Start Dashboard Content -->
            <div class="py-10">
               
                  <div class="bg-gray">
                    <div class="mx-auto max-w-7xl">
                        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-12">
                        
                                                    


                            <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none border-b pb-4">
                                <h3 class="text-2xl leading-6 font-medium " :class="`${textbrand500}`">
                                    Booking Requests
                                </h3>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                    Approve or Reject booking requests
                                </p>
                            </div>
                            
                            <div v-for="request in documents" :key="request.id" class="flex flex-col"> 
                              <div class="mb-2 ml-5 font-semibold text-lg">
                                <p v-if="request.statusPending">
                                  Status: <span class="text-yellow-600">Pending</span>
                                </p>
                                <p v-if="request.statusApproved">
                                  Status: <span class="text-green-600">Approved</span>
                                </p>
                                <p v-if="request.statusRejected">
                                  Status: <span class="text-red-600">Rejected</span>
                                </p>
                              </div>
                              <div class=" -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                  <div class="" :class="request.statusApproved ? 'shadow border-green-300 rounded-md border-2' : 'shadow overflow-hidden border-b border-gray-200 sm:rounded-lg' || request.statusPending ? 'shadow border-yellow-200 rounded-md border' : 'shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'">
                                    <table class="min-w-full divide-y divide-gray-200">
                                      <thead class="bg-gray-50">
                                        <tr>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                            Received On
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Name
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Phone
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Email
                                          </th>
                                          <th scope="col" class="relative px-6 py-3">
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody class="bg-white divide-y divide-gray-200">
                                        <tr >
                                          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {{ request.createdAt.toDate().toDateString() }} @ {{ request.createdAt.toDate().toLocaleTimeString() }} 
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.name }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.phone }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.email }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                          </td>
                                        </tr>
                                      </tbody>
                                      
                                      <thead class="bg-gray-50">
                                        <tr>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Booking For
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Vehicle Year
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Vehicle Model
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Vehicle Type
                                          </th>
                                          <th scope="col" class="relative px-6 py-3">
                                            <span class="sr-only">Edit</span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody class="bg-white divide-y divide-gray-200">
                                        <tr>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {{ request.date}} @ {{ request.time }}:00:00 {{ request.dayTime }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.vehicleYear }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.vehicleModel }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.vehicleType }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                          </td>
                                        </tr>
                                      </tbody>
                                      
                                      <thead class="bg-gray-50">
                                        <tr>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Install Type
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Shade Front
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Shade Rear
                                          </th>
                                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Referred by
                                          </th>
                                          <th scope="col" class="relative px-6 py-3">
                                            <span class="sr-only">Edit</span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody class="bg-white divide-y divide-gray-200">
                                        <tr>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {{ request.installType }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.shadeFront }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.shadeRear }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {{ request.referral }}
                                          </td>
                                          <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table class="w-full border-t border-gray-200">
                                      <thead class="bg-gray-50">
                                        <tr>
                                          <th scope="row" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Special Notes
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody class="bg-white border-t border-b border-gray-200 ">
                                        <tr>
                                          <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                            {{ request.specialNotes }}
                                          </td>
                                        </tr>                              
                                      </tbody>

                                      <div class="my-5 space-x-5 flex justify-center">
                                        <button @click="handleApprove(request.id, request.date, request.time)" class="bg-green-600 px-4 rounded text-white hover:bg-green-900">Approve</button>
                                        <button @click="handleReject(request.id)" class="bg-red-600 px-4 rounded text-white hover:bg-red-900">Reject</button>
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                        
                        
                        </div>
                    </div>
                </div>
            </div>
            <!-- /End Dashboard -->
          </div>
        </div>
      </main>
    </div>
  </div>
  
</template>

<script>
import { ref } from 'vue'
import getCollection from '../../composables/getCollection'
import useDocument from '../../composables/useDocument'
import useCollection from '../../composables/useCollection'

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'
import configSetup from '@/setup.js'

const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const galleryIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const warrantyIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
</svg>

const galleryListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
</svg>

const warrantyListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg>

const pricesIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
</svg>

const calendarIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const navigation = [
  // { name: 'Settings', href: 'Settings', icon: settingsIcon, current: false },
  { name: 'Booking Request', href: 'BookingRequests', icon: calendarIcon, current: true },
  { name: 'Gallery Upload', href: 'ImageLoader', icon: galleryIcon, current: false },
  { name: 'Gallery Edit', href: 'GalleryEdit', icon: galleryListIcon, current: false },
  { name: 'Warranty Creator', href: 'WarrantyCreator', icon: warrantyIcon, current: false },
  { name: 'Warranty Search', href: 'WarrantyList', icon: warrantyListIcon, current: false },
  { name: 'Service Prices', href: 'ServicePrices', icon: pricesIcon, current: false },

]

export default {
    created() {
      const {company} = configSetup()
      document.title = `${company} | Gallery Edit`
    },

    components: {
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        BellIcon,
        MenuAlt2Icon,
        SearchIcon,
        XIcon,
    },

    setup(){
        const sidebarOpen = ref(false)
        const {
            textbrand100,
            textbrand300,
            textbrand500,
            bgbrand600,
            bgbrand800,
            ringbrand500,
            } = configSetup()

        const {documents} = getCollection('companyProfile', 'booking')

        

        const handleReject = async (docId) => {
          const {deleteDoc} = useDocument('companyProfile', docId, 'booking')
          await deleteDoc()
        }

        const handleApprove = async (docId, date, time) => {
          const getMonth = `${date.substring(4,7)} ${date.substring(11,15)}`
          const {updateDoc} = useDocument('companyProfile', docId, 'booking')
          const { addNewDoc } = useCollection('companyProfile', 'schedule', getMonth, date)
          
          await updateDoc({
            statusPending: false,
            statusApproved: true,
          })
          await addNewDoc({
            date: date,
            time: time,
            docRef: docId
          })
        }
        
        
        
    return {
        navigation,
        sidebarOpen,
        textbrand100,
        textbrand300,
        textbrand500,
        bgbrand600,
        bgbrand800,
        ringbrand500,
        documents,
        handleReject,
        handleApprove            
        }
    }

}
</script>